const openBackInStock = () => {
    const queryParameter = 'openBackInStock';
    const targetElement = $('.js_backInStock')
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isAlternativeProduct = urlParams.get(queryParameter)
    if(isAlternativeProduct) {
        targetElement.click();
        window.history.pushState({}, document.title, removeURLParameter(window.location.href,queryParameter));
    }
}

$(document).ready(function () {

    let bannerSliderOptions = {
        "default": {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false,
            fade: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        variableWidth: true,
                        fade: false
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        variableWidth: false,
                        fade: false
                    }
                }
            ]
        },
        "centered": {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            variableWidth: true,
            centerPadding: '10%',
            fade: false,
            responsive: [
                {
                    breakpoint: 1184,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        variableWidth: false,
                        centerPadding: '20%',
                        fade: false
                    }
                }, {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        variableWidth: false,
                        centerPadding: '50px',
                        fade: false
                    }
                }
            ]
        },
        "oneItem": {
            arrows: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            fade: false,
            responsive: [
                {
                    breakpoint: 990,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        variableWidth: false,
                        fade: false
                    }
                }
            ]
        }
    };

    $(".js-bannerSlider").each(function () {
        
        if ($(this).find('.js-bannersSlider__slider').length > 1) {
            var sliderType = $(this).find('.js-bannersSlider__slider').attr('data-slider');
            
            if (!sliderType) {
                sliderType = "default";
            }

            var sliderTypeClass = $(this).hasClass('slider-fullcentered');
            if (sliderTypeClass) {
                sliderType = "centered";
            }

            $(this).slick(bannerSliderOptions[sliderType]);
            handlePlyrInsideSlider($(this));
        }
    });

    $(".js-bannerSlider").each(function () {

        let $slider = $(this);
        if ($slider.hasClass("initialized")) {
            return;
        }

        let counterLi = $slider.find(".slick-dots li").length;
        if (counterLi > 0) {
            let widthLi = 100 / counterLi + '%';
            $slider.find(".slick-dots li").css('width', widthLi);
        }
        
        $slider.addClass("initialized");

    });

    if($('.cmp_relatedByLook').length) {
        $('.cmp_relatedByLook').each(function () {
            let $slider = $(this).find(".js-bannerSlider");
            let $hotspots = $(this).find(".lg-hotspot__button");

            if($hotspots.length) {
                $hotspots.each(function () {
                    let slideIndex = $(this).attr('data-slide');
                    $(this).on('click', function(){
                        $slider.slick('slickGoTo', parseInt(slideIndex)); 
                    })
                });
             };
        });
        
    }

    openBackInStock();
});
